import _union from 'lodash/union';
import _uniqBy from 'lodash/uniqBy';
import _uniq from 'lodash/uniq';
import * as utils from '../../../shared/utils';
import * as pathHelper from '../../../helpers/paths';
import picsioConfig from '../../../../../../config';
import prepareModifiedMetaFields from '../../../helpers/assets/prepareModifiedMetaFields';
import preparePermissions from '../../../helpers/assets/preparePermissions';
import getNegativePermissions from '../../../helpers/assets/getNegativePermissions';
import getDetailsPanelEditable from '../../../components/details/helpers/getDetailsPanelEditable';
import localization from '../../../shared/strings';

function addItemToAggregatedData(selectedAsset, aggregatedData, user, selectedItemsLength, schema) {
  let { flag } = selectedAsset;
  let { title } = selectedAsset;
  let { description } = selectedAsset;
  let { color } = selectedAsset;
  let { rating } = selectedAsset;
  let { keywording } = selectedAsset;
  let { faceRecognizing } = selectedAsset;
  let { faceIndexing } = selectedAsset;
  let { replicating } = selectedAsset;
  let { metadating } = selectedAsset;
  let { transcribing } = selectedAsset;
  let { embedding } = selectedAsset;
  let { CLIPEmbedding } = selectedAsset;
  let { contenting } = selectedAsset;
  let { converting } = selectedAsset;
  let { thumbnailing } = selectedAsset;
  let { keywordingReason } = selectedAsset;
  let { faceRecognizingReason } = selectedAsset;
  let { replicatingReason } = selectedAsset;
  let { metadatingReason } = selectedAsset;
  let { transcribingReason } = selectedAsset;
  let { embeddingReason } = selectedAsset;
  let { contentingReason } = selectedAsset;
  let { thumbnailingReason } = selectedAsset;
  let { keywordingErrorCode } = selectedAsset;
  let { faceRecognizingErrorCode } = selectedAsset;
  let { replicatingErrorCode } = selectedAsset;
  let { metadatingErrorCode } = selectedAsset;
  let { transcribingErrorCode } = selectedAsset;
  let { embeddingErrorCode } = selectedAsset;
  let { contentingErrorCode } = selectedAsset;
  let { convertingErrorCode } = selectedAsset;
  let { thumbnailingErrorCode } = selectedAsset;
  let faces = selectedAsset.faces || [];
  let { trashed } = selectedAsset;
  let { crawling } = selectedAsset;
  let { crawlingReason } = selectedAsset;
  let { crawlingErrorCode } = selectedAsset;
  let { describing } = selectedAsset;
  let { describingReason } = selectedAsset;
  let { describingErrorCode } = selectedAsset;
  let keywords = (selectedAsset?.keywords || []).map((k) => ({ ...k, numberOfAssets: 1 }));
  let { watermarkId } = selectedAsset;
  let tags = selectedAsset.tags.map((tag) => ({
    ...tag, path: pathHelper.checkAndRemoveRootFromTag(tag.path),
  })) || [];
  let lightboards = selectedAsset?.lightboards || [];
  const assignees = aggregatedData?.assignees || [];
  const { restrictSettings } = selectedAsset;
  let { isDownloadable } = selectedAsset || false;
  let { paramsForHighlight } = selectedAsset;
  let isRestricted = utils.isAssetRestricted(selectedAsset.restrictSettings) || false;
  let restrictStartAt = selectedAsset.restrictSettings.startAt || null;
  let restrictExpiresAt = selectedAsset.restrictSettings.expiresAt || null;
  let restrictStartAtPlaceholder = selectedAsset.restrictSettings.startAt || null;
  let restrictExpiresAtPlaceholder = selectedAsset.restrictSettings.expiresAt || null;
  let restrictReason = selectedAsset.restrictSettings.reason || null;
  let modifiedMetaFields = prepareModifiedMetaFields(selectedAsset.modifiedMetaFields);
  let { archived, archivedByReason } = selectedAsset;
  const isRemoveForever = Boolean(selectedAsset.inbox);
  let currentLock;
  let permissions;

  if (Object.keys(aggregatedData).length > 0 && selectedItemsLength > 0) {
    if (aggregatedData.flag !== flag) flag = null;
    if (aggregatedData.title !== title) title = '';
    if (aggregatedData.description !== description) description = '';
    if (aggregatedData.color !== color) color = null;
    if (aggregatedData.rating !== rating) rating = null;
    if (aggregatedData.keywording !== keywording) keywording = null;
    if (aggregatedData.faceRecognizing !== faceRecognizing) faceRecognizing = null;
    if (aggregatedData.faceIndexing !== faceIndexing) faceIndexing = null;
    if (aggregatedData.replicating !== replicating) replicating = null;
    if (aggregatedData.metadating !== metadating) metadating = null;
    if (aggregatedData.transcribing !== transcribing) transcribing = null;
    if (aggregatedData.embedding !== embedding) embedding = null;
    if (aggregatedData.CLIPEmbedding !== CLIPEmbedding) CLIPEmbedding = null;
    if (aggregatedData.contenting !== contenting) contenting = null;
    if (aggregatedData.thumbnailing !== thumbnailing) thumbnailing = null;
    if (aggregatedData.converting !== converting) converting = null;
    if (aggregatedData.keywordingReason !== keywordingReason) keywordingReason = null;
    if (aggregatedData.faceRecognizingReason !== faceRecognizingReason) faceRecognizingReason = null;
    if (aggregatedData.replicatingReason !== replicatingReason) replicatingReason = null;
    if (aggregatedData.metadatingReason !== metadatingReason) metadatingReason = null;
    if (aggregatedData.transcribingReason !== transcribingReason) transcribingReason = null;
    if (aggregatedData.embeddingReason !== embeddingReason) embeddingReason = null;
    if (aggregatedData.contentingReason !== contentingReason) contentingReason = null;
    if (aggregatedData.thumbnailingReason !== thumbnailingReason) thumbnailingReason = null;
    if (aggregatedData.keywordingErrorCode !== keywordingErrorCode) keywordingErrorCode = null;
    if (aggregatedData.faceRecognizingErrorCode !== faceRecognizingErrorCode) faceRecognizingErrorCode = null;
    if (aggregatedData.replicatingErrorCode !== replicatingErrorCode) replicatingErrorCode = null;
    if (aggregatedData.metadatingErrorCode !== metadatingErrorCode) metadatingErrorCode = null;
    if (aggregatedData.transcribingErrorCode !== transcribingErrorCode) transcribingErrorCode = null;
    if (aggregatedData.embeddingErrorCode !== embeddingErrorCode) embeddingErrorCode = null;
    if (aggregatedData.contentingErrorCode !== contentingErrorCode) contentingErrorCode = null;
    if (aggregatedData.convertingErrorCode !== convertingErrorCode) convertingErrorCode = null;
    if (aggregatedData.thumbnailingErrorCode !== thumbnailingErrorCode) thumbnailingErrorCode = null;
    if (aggregatedData.crawling !== crawling) crawling = null;
    if (aggregatedData.crawlingReason !== crawlingReason) crawlingReason = null;
    if (aggregatedData.crawlingErrorCode !== crawlingErrorCode) crawlingErrorCode = null;
    if (aggregatedData.describing !== describing) describing = null;
    if (aggregatedData.describingReason !== describingReason) describingReason = null;
    if (aggregatedData.describingErrorCode !== describingErrorCode) describingErrorCode = null;
    // if one of assets is resticted, we set `isRestricted = true`
    if (aggregatedData.isRestricted) isRestricted = true;
    if (aggregatedData.trashed) trashed = true;
    if (aggregatedData.restrictStartAt !== restrictStartAt) { restrictStartAt = null; }
    if (aggregatedData.restrictExpiresAt !== restrictExpiresAt) { restrictExpiresAt = null; }
    if (aggregatedData.restrictStartAtPlaceholder != restrictStartAtPlaceholder) { restrictStartAtPlaceholder = localization.DETAILS.placeholderMultipleSelection; }
    if (aggregatedData.restrictExpiresAtPlaceholder != restrictExpiresAtPlaceholder) { restrictExpiresAtPlaceholder = localization.DETAILS.placeholderMultipleSelection; }

    if (aggregatedData.isRestricted && aggregatedData.restrictReason !== restrictReason) {
      restrictReason = localization.DETAILS.placeholderMultipleSelection;
    }

    if (aggregatedData.modifiedMetaFields && aggregatedData.modifiedMetaFields.length) {
      modifiedMetaFields = {
        ...modifiedMetaFields,
        ...prepareModifiedMetaFields(aggregatedData.modifiedMetaFields),
      };
    }

    /** paramsForHighlight */
    const assetParamsForHighlight = aggregatedData.paramsForHighlight;
    if (paramsForHighlight) { paramsForHighlight = _union(assetParamsForHighlight, paramsForHighlight); }

    /** keywords */
    if (aggregatedData.keywords?.length) {
      keywords = aggregatedData.keywords.map((k) => ({ ...k }));

      selectedAsset.keywords.forEach((k) => {
        const idx = keywords.findIndex(({ _id }) => k._id === _id);
        const isAlreadyAdded = idx > -1;

        if (isAlreadyAdded) {
          /** Update number of assets on keyword */
          keywords[idx].numberOfAssets = (keywords[idx].numberOfAssets || 1) + 1;
        } else {
          /** Add to keywords */
          keywords.push({ ...k, numberOfAssets: 1 });
        }
      });
    }

    /** watermarks */
    // if one of assets has watermark, we set `isWatermarked = true`
    const assetWatermark = aggregatedData.watermarkId;
    if (assetWatermark) watermarkId = assetWatermark;
    /** collections */
    const assetCollections = aggregatedData.tags;
    if (assetCollections) tags = [...tags, ...assetCollections];

    const assetFaces = aggregatedData.faces;
    if (assetFaces) faces = [...faces, ...assetFaces];

    /** lightboards */
    const assetLightboards = aggregatedData.lightboards;
    if (assetLightboards) lightboards = [...lightboards, ...assetLightboards];

    /** assignees */
    if (selectedAsset.assignees?.length) {
      /** add assignees that wasn't already added */
      selectedAsset.assignees.forEach((a) => {
        if (!assignees.find(({ _id }) => a.assigneeId === _id)) assignees.push({ _id: a.assigneeId });
      });
    }

    /** isDownloadable */
    const assetisIsDownloadable = aggregatedData.isDownloadable;
    if (assetisIsDownloadable === false) {
      isDownloadable = false;
    }

    if (!archived && aggregatedData.isArchived) {
      archived = true;
    }
    if (archived && archivedByReason !== aggregatedData.archivedByReason) {
      archivedByReason = localization.DETAILS.placeholderMultipleSelection;
    }

    /** collections */
    tags = _uniqBy(tags, (collection) => collection._id).map((collection) => ({
      ...collection,
      path: pathHelper.checkAndRemoveRootFromTag(collection.path),
    }));

    /** faces */
    faces = _uniqBy(faces, (face) => face.faceId);

    /** lightboards */
    lightboards = _uniqBy(lightboards, (lihgtboard) => lihgtboard._id).filter(
      (lightboard) => user._id === lightboard.userId,
    );

    // check if changed previewed file or not
    const detailsPanelEditable = getDetailsPanelEditable();
    if (selectedItemsLength === 1) {
      currentLock = aggregatedData?.filename === selectedAsset.name
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    } else {
      currentLock = aggregatedData?.filename === selectedAsset.length
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    }

    let mergedPermissions;
    if (picsioConfig.isMainApp) {
      mergedPermissions = utils.mergePermissions([selectedAsset.permissions, aggregatedData.permissions || {}]);
    }

    const rolePermissions = (picsioConfig.isMainApp && user.role.permissions) || {};
    permissions = preparePermissions(
      { ...mergedPermissions, ...rolePermissions },
      archived,
    );

    /** if one or more assets trashed - disable all permissions */
    if (selectedAsset.trashed) {
      permissions = getNegativePermissions(permissions);
    }
  } else {
    const detailsPanelEditable = getDetailsPanelEditable();
    if (selectedItemsLength === 1) {
      currentLock = aggregatedData?.filename === selectedAsset.name
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    } else {
      currentLock = aggregatedData?.filename === selectedAsset.length
        ? aggregatedData.currentLock
        : detailsPanelEditable.lockAlways;
    }

    let mergedPermissions;
    if (picsioConfig.isMainApp) {
      mergedPermissions = utils.mergePermissions([selectedAsset.permissions]);
    }

    const rolePermissions = (picsioConfig.isMainApp && user.role.permissions) || {};
    permissions = preparePermissions(
      { ...mergedPermissions, ...rolePermissions },
      archived,
    );

    /** if one or more assets trashed - disable all permissions */
    if (selectedAsset.trashed) {
      permissions = getNegativePermissions(permissions);
    }

    if (selectedAsset.assignees?.length) {
      /** add assignees that was already added */
      selectedAsset.assignees.forEach((a) => {
        if (!assignees.find(({ _id }) => a.assigneeId === _id)) assignees.push({ _id: a.assigneeId });
      });
    }
  }

  /** Metadata */
  const assetMeta = selectedAsset.meta || {};
  const prevMeta = aggregatedData.meta || {};
  let meta = assetMeta;
  /** Only if prev aggregatedData has ANY FIELDS => combine metadata */
  if (Object.keys(aggregatedData).length) {
    const metaTitles = _uniq([...Object.keys(assetMeta), ...Object.keys(prevMeta)]);
    /** @type {string[]} */
    const multipleEnumFieldTitles = schema
      .filter(({ type, multiple }) => type === 'enum' && multiple)
      .map(({ title: ttl }) => ttl);

    meta = metaTitles.reduce((result, ttl) => {
      if (multipleEnumFieldTitles.includes(ttl)) {
        /** if Custom field is multiple enum - merge all values */
        if (!prevMeta[ttl]) {
          return {
            ...result,
            [ttl]: typeof assetMeta[ttl] === 'string' ? assetMeta[ttl] : undefined,
          };
        }

        return {
          ...result,
          [ttl]: typeof assetMeta[ttl] === 'string'
            /** if both values exists -> merge */
            ? [...new Set([...prevMeta[ttl].split(','), ...assetMeta[ttl].split(',')])].join(',')
            /** if the asset value is falsy */
            : prevMeta[ttl],
        };
      }
      return {
        ...result,
        [ttl]: assetMeta[ttl] === prevMeta[ttl] ? assetMeta[ttl] : null, // null -> DIFFERENT VALUES
      };
    }, {});
  }

  return {
    color,
    rating,
    flag,
    title,
    description,
    keywords,
    tags,
    faces,
    lightboards,
    keywording,
    faceIndexing,
    replicating,
    metadating,
    contenting,
    converting,
    watermarkId,
    thumbnailing,
    keywordingReason,
    replicatingReason,
    metadatingReason,
    contentingReason,
    crawling,
    crawlingErrorCode,
    crawlingReason,
    thumbnailingReason,
    keywordingErrorCode,
    faceRecognizing,
    faceRecognizingReason,
    faceRecognizingErrorCode,
    replicatingErrorCode,
    metadatingErrorCode,
    transcribing,
    transcribingReason,
    transcribingErrorCode,
    embedding,
    CLIPEmbedding,
    embeddingReason,
    embeddingErrorCode,
    contentingErrorCode,
    convertingErrorCode,
    thumbnailingErrorCode,
    describing,
    describingReason,
    describingErrorCode,
    currentLock,
    assignees,
    isDownloadable,
    paramsForHighlight,
    permissions,
    trashed,
    isRestricted,
    restrictReason,
    restrictStartAt,
    restrictExpiresAt,
    restrictStartAtPlaceholder,
    restrictExpiresAtPlaceholder,
    modifiedMetaFields,
    isRemoveForever,
    isArchived: archived,
    archivedByReason,
    restrictSettings,
    meta,
  };
}
export default addItemToAggregatedData;
